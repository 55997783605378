@import "../../../../src/scss/vendor/foundation/foundation_settings";
@import "../../../../src/scss/abstracts/custom_variables";
@import "../../../../src/scss/abstracts/custom_mixins";

.block_header_cta {
    @include breakpoint(xlarge) {
        .grid-x {
            padding: 58px 0;
        }
    }

    .left {
        @include breakpoint(large down){
            padding: 40px 16px 48px 16px;
        }

        .heading-h1 {
            margin-bottom: 24px;
        }

        .button {
            margin-top: 16px;
        }
    }

    .right {
        text-align: center;
        z-index: 2;

        @include breakpoint(large down) {
            border: 16px solid white;
            padding: 32px 24px;
        }

        .heading-h2 {
            margin-bottom: 20px;
        }

        .button {
            margin-top: 28px;
        }

        .button-subtitle {
            display: block;
            margin-top: 10px;
        }
    }

    ul {
        display: inline-block;
        list-style-type: square;
        margin-bottom: 16px;
    }
}

;@import "sass-embedded-legacy-load-done:192";