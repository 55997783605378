@import "../../../src/scss/themes/basic/abstracts/custom_variables";
@import "_default/block_header_cta.scss";

.block_header_cta {
    background-color: $color-primary;

    &:after {
        background-color: $color-secondary-light;

        @include breakpoint(xlarge) {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
            width: 50vw;
            height: inherit;
            background-image: url("data:image/svg+xml,%3Csvg version= '1.1' id= 'Layer_1' xmlns= 'http://www.w3.org/2000/svg' xmlns:xlink= 'http://www.w3.org/1999/xlink' x= '0px' y= '0px' opacity= '.25' viewBox= '0 0 224 224' style= 'enable-background:new 0 0 224 224;' xml:space= 'preserve' %3E%3Cstyle type= 'text/css' %3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath class= 'st0' d= 'M0,10.7v10.7V32h10.7V21.3V10.7V0H0V10.7z' /%3E%3Cpath class= 'st0' d= 'M21.3,10.7L10.7,21.3L21.3,32L32,21.3L21.3,10.7z' /%3E%3Cpath class= 'st0' d= 'M112,10.7v10.7V32h10.7V21.3V10.7V0H112V10.7z' /%3E%3Cpath class= 'st0' d= 'M133.3,10.7l-10.7,10.7L133.3,32L144,21.3L133.3,10.7z' /%3E%3Cpath class= 'st0' d= 'M60,122.7v10.7V144h10.7v-10.7v-10.7V112H60V122.7z' /%3E%3Cpath class= 'st0' d= 'M81.3,122.7l-10.7,10.7L81.3,144L92,133.3L81.3,122.7z' /%3E%3Cpath class= 'st0' d= 'M172,122.7v10.7V144h10.7v-10.7v-10.7V112H172V122.7z' /%3E%3Cpath class= 'st0' d= 'M193.3,122.7l-10.7,10.7l10.7,10.7l10.7-10.7L193.3,122.7z' /%3E%3C/svg%3E%0A");
            background-attachment: fixed;
            background-repeat: repeat;
            background-size: 224px auto;
        }
    }

    ul li, p {
        font-size: $font-l;
    }

    .left p,
    .left .heading-h1 {
        color: $color-white;
    }

    .right {
        @include breakpoint(xlarge down) {
            background-color: $color-secondary-light;
            background-image: url("data:image/svg+xml,%3Csvg version= '1.1' id= 'Layer_1' xmlns= 'http://www.w3.org/2000/svg' xmlns:xlink= 'http://www.w3.org/1999/xlink' x= '0px' y= '0px' opacity= '.25' viewBox= '0 0 224 224' style= 'enable-background:new 0 0 224 224;' xml:space= 'preserve' %3E%3Cstyle type= 'text/css' %3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath class= 'st0' d= 'M0,10.7v10.7V32h10.7V21.3V10.7V0H0V10.7z' /%3E%3Cpath class= 'st0' d= 'M21.3,10.7L10.7,21.3L21.3,32L32,21.3L21.3,10.7z' /%3E%3Cpath class= 'st0' d= 'M112,10.7v10.7V32h10.7V21.3V10.7V0H112V10.7z' /%3E%3Cpath class= 'st0' d= 'M133.3,10.7l-10.7,10.7L133.3,32L144,21.3L133.3,10.7z' /%3E%3Cpath class= 'st0' d= 'M60,122.7v10.7V144h10.7v-10.7v-10.7V112H60V122.7z' /%3E%3Cpath class= 'st0' d= 'M81.3,122.7l-10.7,10.7L81.3,144L92,133.3L81.3,122.7z' /%3E%3Cpath class= 'st0' d= 'M172,122.7v10.7V144h10.7v-10.7v-10.7V112H172V122.7z' /%3E%3Cpath class= 'st0' d= 'M193.3,122.7l-10.7,10.7l10.7,10.7l10.7-10.7L193.3,122.7z' /%3E%3C/svg%3E%0A");
            background-attachment: fixed;
            background-repeat: repeat;
            background-size: 224px auto;
        }

        .heading-h6 {
            color: $color-white;
        }

        ul li::marker {
            color: $color-white;
        }

        .button-title .btn-subtitle {
            font-size: $font-s;
        }
    }
}
